
import axios, { AxiosError } from 'axios';
import { defineComponent, ref, onMounted, watch, onBeforeMount } from 'vue';
import { useRouter } from "vue-router";
import useAlert from "@/composables/Alert";
import {
  CampHeader,
  CampEmptyListFeedbackV2,
  CampTable,
  CampTableTd,
  CampTableTh
} from '@/components';
import { useLoaderStore } from "@/store/LoaderStore";
import { useCompanyStore } from '@/store/CompanyStore';

interface IAchievementData {
  id: number,
  name: string,
  title: string,
  description_on: string | null,
  description_off: string | null,
  svg_on: string | null,
  svg_off: string | null,
  target: string | null,
  company_id: string | null,
  active: boolean | number,
}

interface IAchievementResponse {
  data: {
    error: boolean,
    message: string,
    data: IAchievementData[]
    errorCode: string
  }
}

export default defineComponent({
  name: "AchievementList",
  components: {
    CampHeader,
    CampEmptyListFeedbackV2,
    CampTable,
    CampTableTd,
    CampTableTh,
  },
  setup() {
    const router = useRouter();
    const { showTimeAlert } = useAlert()
    const keywordSearch = ref("")
    const isLoading = ref(false)
    const achievements = ref<IAchievementData[] | null>(null)
    const achievementsPrint = ref<IAchievementData[] | null>(null)
    const loaderStore = useLoaderStore()
    const companyStore = useCompanyStore()

    // Functions
    async function handleGetAchievementList() {
      try {
        isLoading.value = true
        const result: IAchievementResponse = await axios.get(`/api/getAchievementList`)
        const { data } = result.data
        achievements.value = data
        achievementsPrint.value = data
      } catch (error) {
        if(error instanceof AxiosError)
          showTimeAlert(error.response?.data.message, "error")
        else
          showTimeAlert("Algo deu errado!", "error")
      } finally {
        isLoading.value = false
        loaderStore.close()
      }
    }

    function handleFilterObjectsByKeyword(): boolean {
      if(Array.isArray(achievements.value))
        achievementsPrint.value = achievements.value.filter((item) => {
        for (const key in item) {
          if (key !== 'checked') {
            const propValue = item[key];
            if (typeof propValue === 'string' && propValue.toLowerCase().includes(keywordSearch.value.toLowerCase())) {
              return true;
            }
            if (typeof propValue === 'number' && keywordSearch!.toString() === propValue.toString()) {
              return true;
            }
          }
        }
        return false;
      });
      return false
    }

    // Life Cycles
    onMounted(async () => handleGetAchievementList())

    watch(() => companyStore.id, () => handleGetAchievementList(), {deep: true})

    watch(() => keywordSearch.value, () => handleFilterObjectsByKeyword())

    return {
      router,
      keywordSearch,
      isLoading,
      achievementsPrint,
    }
  }
})
